
/* ------------------Active-User---------------- */

.button-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  border-radius: 6px;
  border: none;

  color: #fff;
  background: linear-gradient(180deg, #7239EA 0%, rgb(99, 144, 228) 100%);
   background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-unselect {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  border-radius: 6px;
  border: none;

  color: black;
  background: linear-gradient(180deg, #dce5f3 0%, #b2caf7 100%);
   background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.flex-item-center{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .total-active-user{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: white;
    padding: 10px 20px;
    font-size: larger;
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  
  
  .font-color-primary{
    color: #707070;
  }


 
  

  /* / --------------------dashboard------------- / */
  .default-color{
    color: #707070;
  }
  .dash-calender {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
  }
  .border-right {
    border-right: 1px solid #707070;
  }
  .dash-text {
    /* cursor: pointer; */
    font-size: 20px;
    margin-bottom: 0px;
    color: #707070;
  }
  .dash-text-num {
    display: flex;
    justify-content: end;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.2;
  }
  .past-week-btn {
    padding: 7px 10px;
    height: 35px;
    background-color: #00a1f1;
    border-radius: 5px;
    cursor: pointer;
  }
  /* ------------------progress circle box ---------------- */

.progress-circle-box-grid{
  display: grid;
  grid-template-columns: 49% 49%;
  gap:10px
}  
.large-text{
  font-size: 20px;
  margin-bottom: -10px;
  text-align: center;
}
.orange-color{
  color: #FCC180
}
.green-color{
  color: #9ED470
}
.red-color{
  color: #FD7F76
}

/* employee */

.user-font-size{
  font-size: 50px;
}
.user-minus-margin{
  margin-top: -20px;
}
.user-minus-margin-5px{
  margin-top: -5px;
}
.border-bootom {
  border-bottom: 1px solid #707070;
}
/* institute wise report */

.user-flex{
  display: flex;
  flex-direction: row;
}
.institute-font-size{
  font-size: 30px;
}
.institute-font-text{
  font-size: 20px;
}
@media (max-width: 800px) {
  .user-flex{
    display: flex;
    font-size: small;
    flex-direction: row;
  }
  .institute-font-size{
    font-size: 10px;
    margin-bottom: 1px;
  }
  .institute-font-text{
    
    font-size: 10px;
  }
  .user-minus-margin{
    margin-top: 0px;
  }
}

.read-input{
  border: 1px solid #e6e8eb;
  border-radius: 5px;
  background: #FFFFFF;
}


/* =========== SpecialLoader ================== */

.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px; /* Updated width */
  height: 80px; /* Updated height */
  background: transparent;
  border: 3px solid rgb(248, 243, 243);
  border-radius: 50%;
  text-align: center;
  line-height: 80px; /* Adjusted line-height */
  font-family: sans-serif;
  font-size: 8px; /* Adjusted font-size */
  color: #6F4DE9;
  letter-spacing: 2px; /* Adjusted letter-spacing */
  text-transform: uppercase;
  text-shadow: 0 0 6px #6F4DE9; /* Adjusted text-shadow */
  box-shadow: 0 0 12px rgba(133, 132, 132, 0.5);
}

.ring:before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid #6F4DE9;
  border-right: 3px solid #6F4DE9;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

.ring1 {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;
}

.ring1:before {
  content: '';
  position: absolute;
  width: 12px; /* Adjusted width */
  height: 12px; /* Adjusted height */
  border-radius: 50%;
  background: #6F4DE9;
  top: -6px;
  right: -6px; /* Adjusted right position */
  box-shadow: 0 0 12px #4423bb; /* Adjusted box-shadow */
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
.pointer{
  cursor: pointer;
}

/*-------suggestion listing -----*/
.auto-suggestion {
  position: relative;
  width: 200px;
}

.input-container {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  cursor: pointer;
}

.input-container input {
  width: 100%;
  border: none;
  background-color: transparent;
}

.suggestion-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 5px;
  background-color: #fff;
  position: absolute;
  width: 96%;
  z-index: 1;
  max-height: 228px;
  overflow-y: scroll;
  li {
      padding: 10px;
      cursor: pointer;
  }
  li:hover {
      background-color: #eee;
  }
}
.suggestion-list::-webkit-scrollbar{
  width: 2px;
}
.suggestion-list::-webkit-scrollbar-thumb{
  background: #888;
}

.suggestion-list .selected {
  background-color: #ddd;
}

.mychip{
  align-items: center;
  background: #0096fb;
  border-radius: 10px;
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  line-height: 15px;

  padding: 1px 4px;
}
.chips{
  display: inline-block;
  background: rgb(224, 224, 224);
  border-radius: 4px;
  margin-right: 3px;
  padding: 5px;
  margin-bottom: 4px;
}
.shimmer{
  padding: 10px;
  height: 50px;
  background: linear-gradient(to right, #eee, #fafafa 50%, #eee)
}

.small-text{
  font-size: x-small;
}