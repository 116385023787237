.login-btn {
    background-color: $sky-blue !important;
  
    // background-color:#08CCFE;
  
    border-radius: 2rem;
  
    color: #fff !important;
  }
  
  .forgot-btn {
    color: $sky-blue !important;
  }
  
  .sign-otp {
    // background-color:$page-bgg !important;
  
    // background-color:#08CCFE;
  
    color: #aaa;
  
    background: #fff;
  
    border-radius: 20px;
  
    padding: 3px 10px;
  
    border: 1px solid #aaa;
  
    line-height: 32px !important;
  
    font-size: 12px;
  
    outline: none;
  
    width: 100%;
  
    margin: 0 0 8px 0;
  }
  
  .input-box {
    border-radius: 2rem;
  }
  
  .msg-login {
    text-transform: none;
  
    font-weight: 600;
  
    letter-spacing: 0.6px;
  
    text-align: left;
  
    font-size: 40px;
  
    padding: 0;
  
    margin: 0;
  }
  
  .login-screem {
    border-bottom: 2px solid $sky-blue !important;
  
    color: #000 !important;
  
    font-weight: 100 !important;
  
    cursor: default;
  
    background-color: #fff;
  
    border-left: none !important;
  
    border-right: none !important;
  
    border-top: none !important;
  
    background-color: transparent !important;
  }
  
  .remember-container {
    color: #555;
  
    font-size: 14px;
  
    padding: 2px 16px 2px 0px;
  
    font-weight: 600;
  
    letter-spacing: 0.8px;
  }
  
  .border-l-non {
    border-right: none;
  }
  
  .border-color-lock {
    background-color: transparent;
  
    border: 1px solid #e1e3ea;
  
    border-left: none;
  
    padding-right: 20px !important;
  
    border-top-right-radius: 21px;
  
    border-bottom-right-radius: 21px;
  }
  
  .input-icon {
    background-color: transparent;
  
    border: 1px solid #e1e3ea;
  
    border-left: none;
  
    padding-right: 20px !important;
  
    border-top-left-radius: 21px;
  
    border-bottom-left-radius: 21px;
  }
  
  .text-font {
    font: 'dfsdgdfhgfh';
  }
  